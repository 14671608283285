<template>
  <div class="profile-component">
    <ProfileImage />

    <ProfilePersonalization
      :is-open="tabsState.personalizationIsOpen"
      @tabClicked="tabsStateUpdate('personalizationIsOpen')"
    />
    <ProfileAdress
      :is-open="tabsState.adressIsOpen"
      @tabClicked="tabsStateUpdate('adressIsOpen')"
    />
    <ProfileProfession
      :is-open="tabsState.professionIsOpen"
      @tabClicked="tabsStateUpdate('professionIsOpen')"
    />
    <ProfileCompany
      :is-open="tabsState.companyIsOpen"
      @tabClicked="tabsStateUpdate('companyIsOpen')"
    />
  </div>
</template>

<script>
import ProfilePersonalization from '@/components/profile/ProfilePersonalization.vue';
import ProfileAdress from '@/components/profile/ProfileAdress.vue';
import ProfileCompany from '@/components/profile/ProfileCompany.vue';
import ProfileProfession from '@/components/profile/ProfileProfession.vue';
import ProfileImage from '@/components/profile/ProfileImage.vue';

import { reactive } from '@vue/reactivity';

import { IonButton } from '@ionic/vue';

export default {
  name: 'MainProfile',
  components: {
    IonButton,
    ProfileImage,
    ProfilePersonalization,
    ProfileAdress,
    ProfileCompany,
    ProfileProfession,
  },
  setup() {
    const tabsState = reactive({
      personalizationIsOpen: false,
      adressIsOpen: false,
      professionIsOpen: false,
      companyIsOpen: false,
    });
    const tabsStateUpdate = (val) => {
      for (let prop in tabsState) {
        if (prop !== val) {
          tabsState[prop] = false;
        }
      }
      tabsState[val] = !tabsState[val];
    };


    return {
      tabsState,
      tabsStateUpdate,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-component {
  width: 100%;
  text-align: left;
}

.save-btn {
  --padding-start: 50px;
  --padding-end: 50px;
}

.rotate-90 {
  .icon {
    transform: rotate(90deg);
    transition: transform 0.5s;
  }
}
.rotate-0 {
  .icon {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }
}

.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s ease-out reverse;
}
@keyframes slide-in {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}
</style>
