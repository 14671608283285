<template>
  <ion-page>
    <ion-header class="profile-header">
      <ion-toolbar class="profile-toolbar">
        <ion-segment
          mode="md"
          :value="currentTab"
          class="segment"
          @ionChange="segmentChanged($event)"
        >
          <ion-segment-button
            class="segment-button"
            value="profile"
          >
            <ion-label class="label">{{$t('profile.profile')}}</ion-label>
          </ion-segment-button>
          <ion-segment-button
            class="segment-button"
            value="settings"
          >
            <ion-label class="label">{{$t('profile.settings')}}</ion-label>
          </ion-segment-button>
          <ion-segment-button
            class="segment-button"
            value="account"
          >
            <ion-label class="label">{{$t('profile.accountTitleText')}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isLoading" >
      <div class="is-flex ion-align-items-center ion-justify-content-center h-100">
        <IonSpinner/>
      </div>
    </ion-content>
    <ion-content
      v-else
      class="ion-padding-start ion-padding-end pt-5"
    >
      <div class="h-100 tablet-centered">
        <MainProfile v-show="currentTab === 'profile'" />
        <ProfileSettings v-show="currentTab === 'settings'"/>
        <ProfileAccount v-show="currentTab === 'account'"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import MainProfile from '@/components/profile/MainProfile.vue';
import ProfileSettings from '@/components/profile/ProfileSettings.vue';
import ProfileAccount from '@/components/profile/ProfileAccount.vue';

import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonRouterOutlet,
  IonSpinner,
  onIonViewWillEnter,
  onIonViewDidEnter,
} from '@ionic/vue';

import { computed, ref } from '@vue/reactivity';

import { useRouter, useRoute } from 'vue-router';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useTracking from "@/composables/useTracking";
import {
  TRACKING_ACCOUNT,
  TRACKING_FAVORITES,
  TRACKING_FOLLOWING,
  TRACKING_PROFILE,
  TRACKING_SETTINGS,
} from "@/config/constants";

export default {
  name: 'Profile',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    MainProfile,
    ProfileSettings,
    ProfileAccount,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonRouterOutlet,
    IonSpinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();

    const currentTab = ref('profile');
    const isLoading = ref(true);

    const profileDetailsFetched = computed(() => store.getters['profile/profileDetailsFetched']);

    const trackTab = () => {
      switch (currentTab.value) {
      case 'profile': trackThis(TRACKING_PROFILE); break;
      case 'settings': trackThis(TRACKING_SETTINGS); break;
      case 'account': trackThis(TRACKING_ACCOUNT); break;
      }
    };

    const segmentChanged = (ev) => {
      currentTab.value = ev.detail.value;
      if (!route.query.tab) {
        trackTab();
      }
    };

    if (!profileDetailsFetched.value) {
      store.dispatch('profile/setProfileDetails');
    }

    onIonViewWillEnter(() => {
      if (route.query.tab) {
        currentTab.value = route.query.tab;
        router.replace(route.path);
      }
    });
    onIonViewDidEnter(() => {
      // console.log('did enter');
      isLoading.value = false;
      trackTab();
    });

    onMounted(() => {
      store.dispatch('profile/getNotificationsPreferences');
    });


    return {
      segmentChanged,
      currentTab,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-tab-button {
  --color: red;
}



/*.profile-toolbar {*/
/*  --background: --ion-background-color;*/
/*}*/

.segment {
  --background: none;
}

ion-segment-button::part(indicator-background) {
  border-radius: 12px;
}

.label {
  font-size: 20px;
}
</style>

