<template>
  <div class="notifications-disabled">
    <div class="title is-flex is-align-items-start ion-align-items-center">
      <div class="item-icon mr-5">
        <IonIcon :icon="notificationsOffOutline"/>
      </div>
      <h2>{{$t('profile.notificationsOffTitle')}}</h2>
    </div>

    <p class="descr mt-1">{{$t('profile.notificationsOffSubTitle')}}</p>

    <div class="buttons">
      <ion-button
        color="light"
        fill="outline"
        mode="ios"
        class="uppercase"
        @click="openAppDetails"
      >
        {{$t('profile.notificationsOffButton')}}
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { notificationsOffOutline } from 'ionicons/icons';
import useNativeSettings from "@/composables/useNativeSettings";

export default {
  name: 'NotificationsDisabled',
  components: {
    IonButton,
    IonIcon,
  },

  setup() {
    const { openAppDetails } = useNativeSettings();
    return {
      openAppDetails,
      notificationsOffOutline,
    };
  },
};
</script>

<style scoped lang="scss">
.notifications-disabled {
  background-color: var(--ion-color-primary);
  padding: 10px;
  border-radius: 6px;
  position: relative;
  color: var(--white);
}

.title {
  color: var(--white);
  margin: 20px 0;

  .item-icon {
    font-size: 30px;
    height: 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    color: var(--white);
  }
}

.descr {
  font-size: 12px;
  color: var(--white);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  ion-button {
    font-size: 14px;
    font-weight: 700;
    --border-width: 2px;
  }
}
</style>
