<template>
  <div class="profession-wrap">
    <div
      class="profession-header ion-justify-content-between is-flex pb-5 pointer"
      @click="tabClicked"
    >
      <h4 class="fw-700 mb-5 uppercase">{{$t('verifyProfile.profession.label')}}</h4>
      <div
        class="profession-icon-wrap"
        :class="[isOpen ? 'rotate-90' : 'rotate-0']"
      >
        <IonIcon
          :icon="chevronForwardOutline"
          class="icon"
        />
      </div>
    </div>
    <transition name="fade">
      <div v-show="isOpen">
        <UserProfessions
          :professions="$store.getters['profile/getUserProfessions']"
          :fetch-user-professions-fn="() => $store.dispatch('profile/setUserProfessions')"
          :delete-user-profession-fn="(object) => $store.dispatch('profile/removeBignumber', object.id)"
          :add-user-profession-fn="(object) => $store.dispatch('profile/addBignumber', object)"
          :update-user-profession-fn="(object) => $store.dispatch('profile/updateBignumber', object)"
          can-add-profession
          can-delete-profession
          can-edit-profession
          :show-title="false"
          @closeTab="tabClicked"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';

import { IonIcon, IonButton } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import UserProfessions from "@/components/profile/professions/UserProfessions";

export default {
  name: 'ProfileProfession',
  components: {
    UserProfessions,
    IonIcon,
    IonButton,
    AppInput,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tabClicked'],

  setup(_, { emit }) {

    const tabClicked = () => emit('tabClicked');
    return {
      chevronForwardOutline,
      tabClicked,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-component {
  width: 100%;
  text-align: left;
}

.profile-card {
  margin-bottom: 25px;
  padding: 20px 0;

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }

  .profile-card-data {
    margin-top: 15px;
    text-align: center;
  }
}

.content > *:not(:last-child) {
  margin-bottom: 10px;
  overflow: hidden;
}

.save-btn {
  --padding-start: 50px;
  --padding-end: 50px;
}
</style>
