<template>
  <ion-item
    color="medium"
    lines="none"
    class="sort-item fz-16 fw-500"
  >
    <ion-label color="primary">{{$t('profile.language')}}</ion-label>
    <ion-select
      interface="action-sheet"
      class="sort-select"
      v-model="locale"
      @ionChange="langChanged"
    >
      <ion-select-option
        v-for="option in selectOptions"
        :key="option.id"
        :value="option.value"
      >
        {{ option.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { PROFILE_LANGUAGE_CHANGE } from '@/config/api';
import axios from '@/http';
import { computed } from '@vue/reactivity';
import { changeLocale } from '@/plugins/i18n';
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n/index';
import useRequestToast from '@/composables/useRequestToast';
import { useStore } from 'vuex';

export default {
  name: 'LanguageButton',
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    selectOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { openErrorToast, openSuccessToast } = useRequestToast();

    const locale = computed({
      get() {
        return store.state.profile.language ?? 'nl';
      },
      set(val) {
        store.commit('profile/changeLanguage', val);
      },
    });

    const langChanged = () => {
      axios.put(PROFILE_LANGUAGE_CHANGE, {
        language: locale.value,
      }).then(() => {
        changeLocale(i18n, locale.value);
        openSuccessToast(t('profile.languageChange'));
      }).catch((err) => {
        openErrorToast(err);
      });
    };

    return { langChanged, locale };
  },
};
</script>

<style scoped lang="scss">
.sort-item {
  --border-radius: 30px;
  --ion-color-base: transparent !important;
  --border-width: 1px;
  --ion-color-shade: var(--ion-color-primary) !important;
}
ion-label {
  padding: 0 80px;
  --color: var(--ion-color-primary);
}
.sort-select {
  width: 0;
  --padding-start: 0px;
  --padding-end: 0px;
  --ion-background-color: #fff;
  --background: transparent;
}
</style>
