<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <div
      class="is-flex is-flex-direction-column ion-justify-content-center"
      style="height: 100%"
    >
      <VueCropper
        v-if="showCropper"
        ref="cropper"
        :aspect-ratio="1 / 1"
        :min-height="minHeight"
        :min-width="minWidth"
        accept="image/png, image/jpeg"
        :src="image"
        :container-style="containerStyle"
        @cropend="cropping"
        @zoom="cropping"
        @ready="handleReady"
      />
    </div>


    <ion-fab
      slot="fixed"
      vertical="bottom"
      edge
      horizontal="start"
    >
      <ion-fab-button
        color="light"
        @click="close"
      >
        <ion-icon :icon="closeOutline" />
      </ion-fab-button>
    </ion-fab>

    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
      edge
    >
      <ion-fab-button
        :disabled="error !== ''"
        @click="handleSubmit"
      >
        <ion-icon
          class="approve-button-icon"
          :icon="checkboxOutline"
        />
      </ion-fab-button>
    </ion-fab>



    <IonLoading :is-open="loading"/>
  </ion-content>
  <ion-footer translucent>
    <ion-toolbar class="is-flex is-flex-direction-column">
      <p
        v-if="!error"
        class="ion-text-center ion-no-margin"
        style="font-size: 14px"
      >
        {{$t('commonKeys.required')}}: {{ minWidth }}/{{ minHeight }}
      </p>
      <p
        class="ion-text-center ion-no-margin"
        style="font-size: 14px"
      >
        {{$t('profile.current')}}: {{ currentWidth }}/{{ currentHeight }}
      </p>
      <p
        v-if="error"
        class="ion-text-center ion-no-margin"
        style="font-size: 14px"
      >
        <ion-text color="danger"> {{ error }} </ion-text>
      </p>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonFabButton,
  IonFab,
  IonIcon,
  IonLoading,
  IonText,
  IonFooter,
  IonToolbar,
} from '@ionic/vue';
import { closeOutline, checkboxOutline } from 'ionicons/icons';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "CropImageModal",
  components: { VueCropper,
    IonContent,
    IonFabButton,
    IonFab,
    IonIcon,
    IonLoading,
    IonText,
    IonFooter,
    IonToolbar,
  },
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    submit: {
      type: Function,
      default: (croppedObj) => {},
    },
    image: {
      type: String,
      required: true,
    },
    minWidth: {
      type: Number,
      default: 512,
    },
    minHeight: {
      type: Number,
      default: 512,
    },
    maxWidth: {
      type: Number,
      default: 1500,
    },
    maxHeight: {
      type: Number,
      default: 1500,
    },
  },

  emits: ['cropped', 'submit'],

  data () {
    return {
      showCropper: false,
      loading: false,

      cropImg: '',
      error: '',
      currentWidth: '',
      currentHeight: '',
      containerStyle: {
        height: '100vh',
      },

      closeOutline, checkboxOutline,
    };
  },

  created() {
    this.loading = true;
  },

  mounted() {
    setTimeout(() => {
      this.showCropper = true;
    }, 500);

  },

  methods: {
    handleReady () {
      this.loading = false;
      this.cropping();
    },
    handleSubmit () {
      const croppedObj = {
        originalImage: this.imgSrc,
        croppedImage: this.cropImg,
      };

      this.submit(croppedObj);
      this.$emit('submit', croppedObj);
      this.close();
    },
    cropping () {
      if (!this.$refs.cropper) {
        return;
      }

      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      const croppedImage = new Image();
      croppedImage.onload = () => {
        this.error = '';
        this.currentWidth = croppedImage.width;
        this.currentHeight = croppedImage.height;
        if (this.minHeight && croppedImage.height < this.minHeight) {
          this.error = this.$t('profile.minHeight') + this.minHeight;
          this.$emit('cropped', {
            originalImage: '',
            croppedImage: 'error',
          });
          return;
        }
        if (this.minWidth && croppedImage.width < this.minWidth) {
          this.error = this.$t('profile.minWidth') + this.minWidth;
          this.$emit('cropped', {
            originalImage: '',
            croppedImage: 'error',
          });
        }
      };

      croppedImage.src = this.cropImg;
    },
  },
};
</script>
