<template>
  <span
    class="tag"
    :class="[type, { 'is-rounded': rounded }]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AppTag",
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
  $tag-background-color: #f5f5f5;
  $tag-color: #4a4a4a;
  .tag {
    align-items: center;
    background-color: $tag-background-color;
    border-radius: 4px;
    color: $tag-color;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
  }

  .tag {
    transition: background-color, color 250ms ease;
  }

  .is-rounded {
    border-radius: 9999px;
  }

  .is-primary {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  .is-success {
    background-color: var(--ion-color-success-shade);
    color: var(--ion-color-success-contrast);
  }

  .is-warning {
    background-color: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  .is-danger {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }

  .is-dark {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);
  }
</style>
