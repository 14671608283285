<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <ion-header
      class="is-flex ion-align-items-center ion-padding-start ion-padding-vertical"
    >
      <h3>
        <b> {{ $t('profile.changePassword') }} </b>
      </h3>

      <ion-buttons class="ml-auto">
        <ion-button
          class="modal-close-button"
          @click="close"
        >
          <ion-icon
            :icon="closeIcon"
            class="ion-margin-start"
          />
        </ion-button>
      </ion-buttons>
    </ion-header>

    <section class="ion-padding-vertical ion-padding-horizontal">
      <div>
        <AppInput
          :value="oldPassword"
          class="w-100 ion-margin-bottom"
          :label="$t('profile.currentPassword')"
          type="password"
          :placeholder="$t('profile.password')"
          :errors="errors?.current_password"
          @input:update="oldPassword = $event"
          @input:enter="changePassword"
        />

        <p> {{ $t('profile.newPassword') }} </p>
        <PasswordRepeat
          v-model:modelPassword="newPassword"
          v-model:modelPasswordRepeat="repeatPassword"
        />
        <ion-button
          mode="ios"
          expand="block"
          class="ion-margin-top"
          @click="changePassword"
        >{{ $t('profile.changePassword') }}</ion-button>
      </div>
    </section>
  </ion-content>
</template>

<script>
import {
  modalController,
  loadingController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';
import { close as closeIcon } from 'ionicons/icons';
import AppInput from '@/components/common/AppInput.vue';
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useRequestToast from '@/composables/useRequestToast';
import PasswordRepeat from "@/components/common/PasswordRepeat";

export default {
  name: 'ProfileChangePasswordModal',
  components: {
    PasswordRepeat,
    AppInput,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const oldPassword = ref(null);
    const newPassword = ref(null);
    const repeatPassword = ref(null);
    const passwordVerified = ref(false);
    const errors = ref(null);

    const { openErrorToast } = useRequestToast();

    const changePassword = async () => {
      const loading = await loadingController.create();
      await loading.present();
      store
        .dispatch('profile/changePassword', {
          current_password: oldPassword.value,
          new_password: newPassword.value,
          new_password_confirmation: repeatPassword.value,
        })
        .then(() => {
          store
            .dispatch('authentication/logout')
            .finally(async () => {
              await router.push('/login');
              props.close();
            });
        })
        .catch((err) => {
          errors.value = err?.response?.data?.errors;
          openErrorToast(err);
        })
        .finally(async () => {
          await loading.dismiss();
        });
    };

    return {
      oldPassword,
      newPassword,
      repeatPassword,
      passwordVerified,
      changePassword,
      errors,
      closeIcon,
    };
  },
};
</script>
