<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <ion-header class="is-flex ion-align-items-center ion-padding-start ion-padding-vertical">
      <h3>
        <b> {{ $t('profile.googleTwoFactorAuth') }} </b>
      </h3>

      <ion-buttons class="ml-auto">
        <ion-button
          class="modal-close-button"
          mode="ios"
          @click="close"
        >
          <ion-icon :icon="closeIcon" />
        </ion-button>
      </ion-buttons>
    </ion-header>

    <section class="ion-padding-vertical ion-padding-horizontal">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="verifyPasswordInput"
          :key="1"
        >
          <p class="mb-2"> {{ $t('profile.confirmPasswordToContinue') }} </p>

          <AppInput
            :value="password"
            class="w-100"
            type="password"
            :placeholder="$t('login.passwordLabel')"
            :errors="passwordErrors"
            @input:update="password = $event"
            @input:enter="verifyPassword"
          />
          <ion-button
            mode="ios"
            expand="block"
            shape="round"
            :disabled="!!countDownValue"
            class="ion-margin-top"
            @click="verifyPassword"
          >
            {{ countDownValue ? countDownValue : $t('profile.verifyPassword') }}
          </ion-button>
        </div>

        <QRCodeInput
          v-else-if="qrCodeInput"
          :password="password"
          :loading="qrCodeLoading"
          :key="2"
          @refresh="gerQrAndSecret"
          @confirmed="fetchUserAndClose"
        />

        <TwoFAVerifyCodeInput
          v-else-if="verify2FACodeInput"
          :button-label="$t('profile.twoFADisable')"
          :disabled-till="countDownValue"
          @submit="disable2Fa"
        />
      </transition>
    </section>
  </ion-content>
</template>

<script>
import {
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
  modalController,
  loadingController,
} from '@ionic/vue';

import { computed, ref } from '@vue/reactivity';

import { close as closeIcon } from 'ionicons/icons';

import AppInput from '@/components/common/AppInput.vue';
import QRCodeInput from "@/components/profile/two-fa/QRCodeInput";
import TwoFAVerifyCodeInput from '@/components/login/TwoFAVerifyCodeInput';

import { useStore } from 'vuex';
import useCountDown from "@/composables/useCountDown";
import useRequestToast from "@/composables/useRequestToast";


export default {
  name: "TwoFASwitcherModal",
  components: {
    QRCodeInput,
    AppInput,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
    TwoFAVerifyCodeInput,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
  },


  setup (props) {
    const store = useStore();
    const { openErrorToast } = useRequestToast();
    const { startCountDown, countDownValue } = useCountDown();

    const password = ref(null);
    const verifyPasswordInput = ref(true);
    const verify2FACodeInput = ref(false);
    const qrCodeInput = ref(false);
    const qrCodeLoading = ref(false);

    const passwordErrors = ref([]);

    const twoFAEnabled = computed(() => !!store.getters['authentication/getUser']?.optional_2fa_enabled);

    const gerQrAndSecret = () => {
      qrCodeLoading.value = true;
      store.dispatch('profile/generate2FAQRAndSecret', { password: password.value })
        .then(( { data } ) => {
          store.commit('authentication/setQrImage', data.qr_image);
          store.commit('authentication/setSecretKey', data.secret_key);
        })
        .catch(openErrorToast)
        .finally( () => {
          qrCodeLoading.value = false;
        });
    };

    const verifyPassword = async () => {
      const loading = await loadingController.create();
      await loading.present();

      passwordErrors.value = [];

      store.dispatch('profile/verifyPassword2fa', { password: password.value })
        .then(() => {
          verifyPasswordInput.value = false;

          if (twoFAEnabled.value) {
            verify2FACodeInput.value = true;
          } else {
            qrCodeInput.value = true;
            gerQrAndSecret();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            passwordErrors.value = error.response.data.errors.password;
            if (error.response.data.errors.wait_till) {
              startCountDown(error.response.data.errors.wait_till[0]);
            }
          }
          openErrorToast(error);
        })
        .finally(async () => {
          await loading.dismiss();
        });
    };

    const fetchUserAndClose = () => {
      store.dispatch('authentication/fetchUser');
      props.close();
    };

    const disable2Fa = async (code) => {
      const loading = await loadingController.create();
      await loading.present();

      store.dispatch('profile/disable2FA', { password: password.value, verify_code: code })
        .then(() => {
          fetchUserAndClose();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            if (error.response.data.errors.wait_till) {
              startCountDown(error.response.data.errors.wait_till[0]);
            }
          }
          openErrorToast(error);
        })
        .finally(async () => {
          await loading.dismiss();
        });
    };

    return {
      password,
      verifyPasswordInput,
      qrCodeInput,
      verify2FACodeInput,
      countDownValue,
      twoFAEnabled,
      qrCodeLoading,
      passwordErrors,

      closeIcon,

      gerQrAndSecret,
      disable2Fa,
      verifyPassword,
      fetchUserAndClose,
    };
  },
};
</script>
