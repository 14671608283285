<template>
  <div class="personalization-wrap">
    <div
      class="personalization-header ion-justify-content-between is-flex pb-5 pointer"
      @click="tabClicked"
    >
      <h4 class="fw-700 mb-5 uppercase">{{$t('profile.personalization')}}</h4>
      <div
        class="personalization-icon-wrap"
        :class="[isOpen ? 'rotate-90' : 'rotate-0']"
      >
        <IonIcon
          :icon="chevronForwardOutline"
          class="icon"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isOpen"
        class="personalization-content content is-flex is-flex-direction-column"
      >
        <div class="salutation-select ion-margin-bottom">
          <AppSelect
            v-model="salutation"
            class="select"
            :placeholder="$t('profile.salutationLabel')"
            :items="[
              { id: 'Dhr.', name: 'Dhr.' },
              { id: 'Mevr.', name: 'Mevr.' },
            ]"
          />
        </div>
        <AppInput
          :placeholder="$t('profile.titleLabel')"
          :value="title"
          @input:update="title = $event"
        />
        <AppInput
          :placeholder="$t('profile.initialsLabel')"
          :value="initials"
          @input:update="initials = $event"
        />
        <AppInput
          :placeholder="$t('profile.firstNameLabel')"
          :value="firstName"
          :errors="errors?.first_name"
          @input:update="firstName = $event"
        />
        <AppInput
          :placeholder="$t('profile.insertionLabel')"
          :value="insertion"
          @input:update="insertion = $event"
        />
        <AppInput
          :placeholder="$t('profile.lastNameLabel')"
          :value="lastName"
          :errors="errors?.last_name"
          @input:update="lastName = $event"
        />
        <ion-item
          class="date-time-item"
          color="medium"
        >
          <AppDatetimePicker
            :model-value="birthdayDate"
            @ionChange="setBirthDay"
          />
        </ion-item>
        <div class="save-btn-wrap ion-text-center pb-5">
          <ion-button
            mode="ios"
            fill="outline"
            class="uppercase --border-radius save-btn"
            @click="submitForm"
          >
            {{$t('commonKeys.save')}}
          </ion-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import AppSelect from '@/components/common/AppSelect.vue';
import useRequestToast from '@/composables/useRequestToast';

import { IonIcon, IonButton, IonItem, IonLabel } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

import { computed, reactive, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n/index';
import AppDatetimePicker from "@/components/common/AppDatetimePicker";

export default {
  name: 'ProfilePersonalization',
  components: {
    AppDatetimePicker,
    IonIcon,
    IonButton,
    IonItem,
    IonLabel,
    AppInput,
    AppSelect,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tabClicked'],

  setup(_, { emit }) {
    const store = useStore();
    const { openErrorToast, openSuccessToast } = useRequestToast();
    const { t } = useI18n();

    const successMessage = t('profile.profileUpdatedMessage');

    const errors = ref(null);
    const bdate = ref(null);
    const firstNameErrors = reactive([]);
    const lastNameErrors = reactive([]);

    const salutation = computed({
      get() {
        return store.state.profile.profileDetails.salutation;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { salutation: val });
      },
    });
    const title = computed({
      get() {
        return store.state.profile.profileDetails.title;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { title: val });
      },
    });
    const initials = computed({
      get() {
        return store.state.profile.profileDetails.initials;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { initials: val });
      },
    });
    const firstName = computed({
      get() {
        return store.state.profile.profileDetails.first_name;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { first_name: val });
      },
    });
    const insertion = computed({
      get() {
        return store.state.profile.profileDetails.insertion;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { insertion: val });
      },
    });
    const lastName = computed({
      get() {
        return store.state.profile.profileDetails.last_name;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { last_name: val });
      },
    });
    const birthdayDate = computed({
      get() {
        let birthDate = store.state.profile.profileDetails.birth_date;
        if (birthDate !== null && birthDate.indexOf('/') !== -1) {
          birthDate = birthDate.split('/').reverse().join('-');
        }
        return birthDate;
      },
      set(val) {
        const trimmDate = val.substr(0, 10);
        store.commit('profile/updateProfileDetails', { birth_date: trimmDate });
      },
    });

    const setBirthDay = (value) => {
      const [year, month, day] = value.split('-');
      birthdayDate.value = `${day}/${month}/${year}`;
    };


    const tabClicked = () => emit('tabClicked');

    const submitForm = () => {
      store
        .dispatch('profile/updatePersonalization', {
          salutation: salutation.value,
          title: title.value,
          initials: initials.value,
          first_name: firstName.value,
          insertion: insertion.value,
          last_name: lastName.value,
          birth_date: birthdayDate.value,
        })
        .then(() => {
          errors.value = null;
          emit('tabClicked');
          openSuccessToast(successMessage);
        })
        .catch((err) => {
          errors.value = err?.response?.data?.errors;
          openErrorToast(err);
        });
    };
    return {
      salutation,
      title,
      initials,
      firstName,
      insertion,
      lastName,
      birthdayDate,
      chevronForwardOutline,
      firstNameErrors,
      lastNameErrors,
      errors,
      tabClicked,
      submitForm,
      bdate,
      setBirthDay,
    };
  },
};
</script>

<style lang="scss" scoped>
.content > *:not(:last-child) {
  margin-bottom: 10px;
  overflow: hidden;
}

.save-btn {
  --padding-start: 50px;
  --padding-end: 50px;
}
ion-item {
  --inner-border-width: 0;
}
.date-time-item {
  border-radius: 10px;
  --min-height: 50px;
  font-size: 14px;
  background: var(--input-background);

}
.date-time-item::part(native) {
  border: none;
}

.salutation-select {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: var(--input-background);
  min-height: 50px;
  font-size: 14px;

  .select {
    width: 100%;
  }
}
</style>

<style lang="scss">
  .salutation-select ion-select {
    --placeholder-opacity: 0.5;
  }
</style>
