<template>
  <div class="profile-card ion-align-items-center is-flex is-flex-direction-column">
    <ion-row class="ion-align-items-center">
      <ion-col
        v-if="profileImg"
        class="mr-4 pointer"
        @click="deleteImagePrompt"
      >
        <ion-icon
          :icon="trashOutline"
          class="profile-control"
        />
      </ion-col>
      <ion-col
        class="is-relative pointer"
        @click="presentActionSheet"
      >
        <div class="avatar">
          <ion-img
            v-if="profileImg"
            :src="profileImg"
          />
          <ion-img
            v-else
            class="profile-placeholder"
            src="./assets/avatar.png"
          />
          <ion-icon
            v-if="!profileImg"
            :icon="camera"
            class="profile-control on-image-control"
          />
        </div>
      </ion-col>
      <ion-col
        v-if="profileImg"
        class="ml-4 pointer"
        @click="presentActionSheet"
      >
        <ion-icon
          :icon="cameraOutline"
          class="profile-control"
        />
      </ion-col>
    </ion-row>

    <div class="profile-card-data">
      <h5 class="profile-name mb-3">
        {{ completeName }}
      </h5>
      <p>{{ userProfessions }}</p>
    </div>
  </div>
</template>

<script>
import { camera, cameraOutline, trashOutline } from 'ionicons/icons';
import {
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  modalController,
  actionSheetController,
  loadingController, alertController,
} from "@ionic/vue";
import CropImageModal from "./CropImageModal";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const b64toBlob = require('b64-to-blob');
import Compressor from 'compressorjs';
import useRequestToast from "@/composables/useRequestToast";
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n/index';

export default {
  name: "ProfileImage",
  components: {
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    CropImageModal,
  },
  emits: ['update-image'],

  setup (_, { emit }) {
    const { t } = useI18n();
    const { openErrorToast, openSuccessToast } = useRequestToast();
    const store = useStore();

    const profileDetails = computed(() => store.getters['profile/getProfileDetails']);

    const completeName = computed(() => {
      const salutation = profileDetails.value.salutation;
      const initials = profileDetails.value.initials;
      let firstName = profileDetails.value.first_name;

      if ((salutation || initials) && firstName) {
        firstName = '(' + firstName + ')';
      }

      const insertion = profileDetails.value.insertion;
      const last_name = profileDetails.value.last_name;
      const title = profileDetails.value.title ? `, ${profileDetails.value.title}` : null;

      const fullName = [salutation, initials, firstName, insertion, last_name, title].filter(Boolean);

      return fullName.join(' ');
    });

    const userProfessions = computed(() => {
      const professions = store.getters['profile/getUserProfessions'] ?? [];
      return professions.map(p => p.profession_details.name).join(', ');
    });

    const profileImg = computed(() => {
      const profileMedia = profileDetails.value?.media ?? [];
      return profileMedia.length && profileMedia.filter(media => media.size === 'small')[0].url;
    });


    const updateImage = async (cropObj) => {
      const loader = await loadingController.create();
      await loader.present();

      try {
        await store.dispatch('profile/saveImage', {
          originalImage: cropObj.croppedImage,
          croppedImage: cropObj.croppedImage,
        });

        await openSuccessToast(t('commonKeys.successfullyUpdated'));

        await store.dispatch('profile/setProfileDetails');
      } catch (error) {
        openErrorToast(error);
      } finally {
        await loader.dismiss();
      }
    };

    const deleteImage = async () => {
      const media = store.getters['profile/getProfileDetails']?.media ?? [];
      const mediaId = media.find(media => media.size === 'cropped').id ?? null;

      if (!mediaId) { return; }

      const loader = await loadingController.create();
      await loader.present();

      try {
        await store.dispatch('profile/deleteMedia', mediaId);

        await openSuccessToast(t('commonKeys.successfullyDeleted'));
        await store.commit('profile/updateProfileDetails', { media: [] });
      } catch (error) {
        openErrorToast(error);
      } finally {
        await loader.dismiss();
      }
    };

    const onCropSubmit = (cropObj) => {
      emit('update-image', cropObj);
      updateImage(cropObj);
    };


    /**  Image selected START **/
    const openCropperModal = async (image) => {
      const modal = await modalController
        .create({
          component: CropImageModal,
          componentProps: {
            image,
            close: modalController.dismiss,
            submit: onCropSubmit,
          },
        });
      await modal.present();
    };

    const getOptimized = (blob) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(blob, {
          quality: 0.6,

          success (result) {
            resolve(result);
          },
          error (err) {
            console.log(err.message);
          },
        });
      });
    };

    const setImage = async (eventSource) => {
      const image = await Camera.getPhoto({
        quality: 90,
        source: eventSource,
        resultType: CameraResultType.Base64,
      });

      const imageUrl = image.base64String;
      const blob = b64toBlob(imageUrl, `image/${image.format}`);
      const optimizedBlob = await getOptimized(blob);

      const reader = new FileReader();
      reader.readAsDataURL(optimizedBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        openCropperModal(base64data);
      };
    };
    /**  Image selected END **/


    /**  Select source START  **/
    const imageOptions = [
      {
        text: t('profile.camera'),
        handler: () => setImage(CameraSource.Camera),
      },
      {
        text: t('profile.gallery'),
        handler: () => setImage(CameraSource.Photos),
      },
      {
        text: t('commonKeys.cancel'),
        role: 'cancel',
      },
    ];
    const presentActionSheet = async () => {
      const actionSheet = await actionSheetController.create({
        header: t('profile.profileImage'),
        buttons: imageOptions,
      });
      return actionSheet.present();
    };
    /**  Select source END  **/

    const deleteImagePrompt = async() => {
      const alert = await alertController
        .create({
          header: t('profile.deleteImage'),
          message: t('profile.deleteImageConfirmation'),
          buttons: [
            { text: t('commonKeys.no') },
            {
              text: t('commonKeys.yes'),
              handler: deleteImage,
            },
          ],
        });
      return alert.present();
    };

    return {
      profileImg,
      completeName,
      profileDetails,
      userProfessions,
      deleteImagePrompt,
      presentActionSheet,
      cameraOutline, trashOutline, camera,
    };
  },
};
</script>

<style scoped lang="scss">
$avatar-height: 110px;
$avatar-width: $avatar-height;

.profile-placeholder {
  background-color: rgba(255, 255, 255, 0.4);
}

.profile-card {
    margin-bottom: 25px;
    padding: 10px 0;

  .profile-card-data {
    margin-top: 15px;
    text-align: center;
  }
}

.avatar {
  width: $avatar-width;
  min-width: $avatar-width;
  height: $avatar-height;
  border-radius: 50%;
  overflow: hidden;

  ion-img {
    min-width: $avatar-width;
    min-height: $avatar-height;
  }
}


.profile-control {
  font-size: 27px;
}

.on-image-control {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}

</style>
