<template>
  <div>
    <AppInput
      :value="computedPass"
      class="w-100 mt-3"
      :icon="lockClosedOutline"
      autocomplete="new-password"
      :placeholder="$t('login.passwordLabel')"
      :errors="passwordErrors"
      :errors-all="true"
      type="password"
      @blur="validatePasswordRules"
      @input:update="computedPass = $event"
    />

    <div class="ion-margin-top tags">
      <app-tag
        v-for="rule in passwordRules"
        :key="rule.label"
        :type="rule.valid ? 'is-success': 'is-incorrect'"
      >{{ rule.label }}</app-tag>

      <app-tag
        :type="passwordMatch ? 'is-success': 'is-incorrect'"
      > {{ $t('passwordRepeat.passwordMatch')}} </app-tag>
    </div>

    <AppInput
      :value="computedPassRepeat"
      class="w-100 mt-3"
      :icon="lockClosedOutline"
      :placeholder="$t('passwordRepeat.passwordRepeat')"
      autocomplete="new-password"
      type="password"
      @input:update="computedPassRepeat = $event"
    />
  </div>
</template>

<script>
import AppInput from "@/components/common/AppInput";
import AppTag from "@/components/common/AppTag";

import usePasswordValidation from "@/composables/usePasswordValidation";
import { ref, computed  } from "@vue/reactivity";
import { watchEffect} from "@vue/runtime-core";
import {lockClosedOutline, mailOutline} from "ionicons/icons";

export default {
  name: "PasswordRepeat",

  props: {
    modelPassword: String,
    modelPasswordRepeat: String,
    modelPasswordValid: Boolean,
  },

  components: {
    AppInput,
    AppTag,
  },
  emits: ['update:modelPassword', 'update:modelPasswordRepeat', 'update:modelPasswordValid'],

  setup(props, { emit }) {
    const { password, errors: passwordErrors, validateRules: validatePasswordRules, rules: passwordRules, valid: rulesValid } = usePasswordValidation();

    const passwordRepeat = ref(null);

    const passwordMatch = computed(() => password.value && (password.value === passwordRepeat.value));
    const passwordValid = computed(() => !!(passwordMatch.value && rulesValid.value));


    // password
    const computedPass = computed({
      get: () => password.value,
      set: (value) => {
        password.value = value;
        emit('update:modelPassword', value);
      },
    });

    watchEffect(() => {
      password.value = props.modelPassword;
    });

    // password repeat
    const computedPassRepeat = computed({
      get: () => passwordRepeat.value,
      set: (value) => {
        passwordRepeat.value = value;
        emit('update:modelPasswordRepeat', value);
      },
    });

    watchEffect(() => {
      passwordRepeat.value = props.modelPasswordRepeat;
    });

    // password valid emit only
    watchEffect(() => {
      emit('update:modelPasswordValid', passwordValid.value);
    });

    return {
      mailOutline,
      lockClosedOutline,

      passwordErrors,
      passwordRules,
      passwordMatch,
      passwordValid,
      computedPass,
      computedPassRepeat,
      validatePasswordRules,
    };
  },

};
</script>


<style scoped lang="css">
  .tag.is-incorrect{
    background-color: #282828;
    color: #FFF;
  }
</style>
