<template>
  <div class="company-wrap">
    <div
      class="company-header ion-justify-content-between is-flex pb-5 pointer"
      @click="tabClicked"
    >
      <h4 class="fw-700 mb-5 uppercase">{{$t('profile.company')}}</h4>
      <div
        class="company-icon-wrap"
        :class="[isOpen ? 'rotate-90' : 'rotate-0']"
      >
        <IonIcon
          :icon="chevronForwardOutline"
          class="icon"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isOpen"
        class="company-content content is-flex is-flex-direction-column"
      >
        <AppInput
          :placeholder="$t('contact.name')"
          :value="name"
          @input:update="name = $event"
        />
        <AppInput
          :placeholder="$t('profile.companyKVK')"
          :value="kvk"
          :errors="errors?.kvk_company"
          @input:update="kvk = $event"
        />
        <AppInput
          :placeholder="$t('profile.companyAGB')"
          :value="agb"
          :errors="errors?.agb_number"
          @input:update="agb = $event"
        />
        <AppInput
          :placeholder="$t('profile.companyBTW')"
          :value="btw"
          :errors="errors?.btw_number"
          @input:update="btw = $event"
        />
        <div class="save-btn-wrap ion-text-center pb-5">
          <ion-button
            mode="ios"
            fill="outline"
            class="uppercase --border-radius save-btn"
            @click="submitForm"
          >
            {{$t('commonKeys.save')}}
          </ion-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import useRequestToast from '@/composables/useRequestToast';

import { IonIcon, IonButton } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'ProfileCompany',
  components: {
    IonIcon,
    IonButton,
    AppInput,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tabClicked'],

  setup(_, { emit }) {
    const store = useStore();
    const { openErrorToast, openSuccessToast } = useRequestToast();
    const successMessage = 'Profile updated successfully';

    const errors = ref(null);

    const name = computed({
      get() {
        return store.state.profile.profileDetails.company;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { company: val });
      },
    });
    const kvk = computed({
      get() {
        return store.state.profile.profileDetails.kvk_company;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { kvk_company: val });
      },
    });
    const agb = computed({
      get() {
        return store.state.profile.profileDetails.agb_number;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { agb_number: val });
      },
    });
    const btw = computed({
      get() {
        return store.state.profile.profileDetails.btw_number;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { btw_number: val });
      },
    });

    const tabClicked = () => emit('tabClicked');

    const submitForm = () => {
      store
        .dispatch('profile/updateCompany', {
          company: name.value,
          kvk_company: kvk.value,
          agb_number: agb.value,
          btw_number: btw.value,
        })
        .then(() => {
          errors.value = null;
          emit('tabClicked');
          openSuccessToast(successMessage);
        })
        .catch((err) => {
          errors.value = err?.response?.data?.errors;
          openErrorToast(err);
        });
    };
    return {
      name,
      kvk,
      agb,
      btw,
      chevronForwardOutline,
      errors,
      tabClicked,
      submitForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-component {
  width: 100%;
  text-align: left;
}

.profile-card {
  margin-bottom: 25px;
  padding: 20px 0;

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }

  .profile-card-data {
    margin-top: 15px;
    text-align: center;
  }
}

.content > *:not(:last-child) {
  margin-bottom: 10px;
  overflow: hidden;
}

.save-btn {
  --padding-start: 50px;
  --padding-end: 50px;
}

</style>
